$( document ).ready(function() {

    $('.areaEpci').click(function ()
    {
        var epciNom = $(this).data('nom');
        var epciId = $(this).data('id');
        document.getElementById('res_region_choix').innerHTML = epciNom;
        document.getElementById('recherche_departement_carto_typeRecherche').value = "mapEpci";
        document.getElementById('recherche_departement_carto_valueMap').value = epciId;
    });




    $('.areaCommune').click(function () {
        var communeNom = $(this).data('nom');
        var communeId = $(this).data('id');
        document.getElementById('res_region_choix').innerHTML = communeNom;
        document.getElementById('recherche_departement_carto_typeRecherche').value = "mapCommune";
        document.getElementById('recherche_departement_carto_valueMap').value = communeId;
    });



    $('#recherche_departement_carto_commune').change(function(){
        var communeNom = $(this).find("option:selected").text();
        document.getElementById('res_region_choix').innerHTML = communeNom;
        document.getElementById('recherche_departement_carto_typeRecherche').value = "selectCommune";
        document.getElementById('recherche_departement_carto_valueMap').value = null;
    });

    /*$('#selectCommune2').click(function () {
        var el = document.getElementById('recherche_departement_carto_commune');
        var text = el.options[el.selectedIndex].innerHTML;
        document.getElementById('res_region_choix').innerHTML = text;
        document.getElementById('recherche_departement_carto_typeRecherche').value = "selectCommune";
        document.getElementById('recherche_departement_carto_valueMap').value = null;
    });*/





    $('.submitRecherche').click(function () {
        var type = $('#recherche_departement_carto_typeRecherche').val();
        var valueMap =  $('#recherche_departement_carto_valueMap').val();
        var commune =  $('#recherche_departement_carto_commune').val();
        var federation =  $('#recherche_departement_carto_federation').val();

        console.log('type: ' + type + ' valueMap: ' + valueMap + ' commune: ' + commune + ' fede: ' + federation);
        console.log($('#recherche_departement_carto_typeRecherche').val());

        var oneSelected = false;
        var index = 0;
        var exit = false;
        while (exit == false) {
            //recherche_departement_carto_labels_1
            if (document.getElementById('recherche_departement_carto_labels_' + index) == null) {
                exit = true;
            } else {
                if (document.getElementById('recherche_departement_carto_labels_' + index).checked) {
                    oneSelected = true;
                    exit = true;
                }
            }
            index = index + 1;
        }

        if (type == '' && valueMap == '' && commune == '' && federation == '' && oneSelected == false) {
            alert("Vous devez renseigner au moins un champ");
        } else {
            //document.rechercheDepartement.submit();
            document.recherche_departement_carto.submit();
        }
    });



});